import React from 'react'
import Loading from '../../assets/images/loading.inline.svg'

const EcranLoading = ({ title }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-12">
      <h1 className="mb-12 text-3xl font-bold text-center">{title}</h1>
      <Loading className="w-5 h-5 text-green-500 animate-spin" />
    </div>
  )
}

export default EcranLoading
