import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import Poll from '../../utils/Poll'

import EcranLoading from './EcranLoading'
import ecranState from './ecranState'
import resultsState from './resultsState'

const EcranSignature = () => {
  const [member, setMember] = useState(null)
  const [procedure, setProcedure] = useState(null)
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)
  const [resultats, _] = useRecoilState(resultsState)
  useEffect(() => {
    axios
      .post(`${process.env.GATSBY_WIZZAS_API_URL}/create-contract-documents`, {
        'PERSONNES.SOUSCRIPTEUR.MAIL': resultats['PERSONNES.SOUSCRIPTEUR.MAIL'],
        'PERSONNES.SOUSCRIPTEUR.CIVILITE':
          resultats['PERSONNES.SOUSCRIPTEUR.CIVILITE'],
        ID_POL: resultats['ID_POL'],
        type: resultats.type,
      })
      .then((createdDocumentsRequest) => {
        setMember(createdDocumentsRequest.data.member)
        setProcedure(createdDocumentsRequest.data.procedureId)
      })
  }, [])

  useEffect(() => {
    if (procedure !== null) {
      new Poll(
        `${process.env.GATSBY_WIZZAS_API_URL}/is-procedure-finished`,
        {
          'PERSONNES.SOUSCRIPTEUR.CIVILITE':
            resultats['PERSONNES.SOUSCRIPTEUR.CIVILITE'],
          'PERSONNES.SOUSCRIPTEUR.NOM': resultats['PERSONNES.SOUSCRIPTEUR.NOM'],
          'PERSONNES.SOUSCRIPTEUR.MAIL':
            resultats['PERSONNES.SOUSCRIPTEUR.MAIL'],
          procedure_id: procedure,
          type: resultats.type,
          ID_POL: resultats['ID_POL'],
        },
        (res) => res.data.status === 'active',
        () => {
          setIndexEcran(indexEcran + 1)
          clearInterval(interval)
        },
        2000
      )
    }
  }, [procedure])

  return !member ? (
    <EcranLoading
      title={`Merci ${resultats['PERSONNES.SOUSCRIPTEUR.PRENOM']}, nous finalisons vos documents contractuels...`}
    />
  ) : (
    <div className="flex flex-col w-full h-full p-4 md:p-12">
      <p className="mb-6 text-center">
        Voici votre contrat et votre mandat SEPA, merci de bien vouloir les lire
        attentivement puis les signer.
      </p>
      {process.env === 'development' ? (
        // signatureUI Id de dev
        <iframe
          className="flex-auto w-full"
          src={`${process.env.GATSBY_YOUSIGN_API_URL}/procedure/sign?members=${member}&signatureUi=/signature_uis/47ec13be-b357-4167-ac61-e5a78f504418`}
        ></iframe>
      ) : (
        // signatureUI Id de prod
        <iframe
          className="flex-auto w-full"
          src={`${process.env.GATSBY_YOUSIGN_API_URL}/procedure/sign?members=${member}&signatureUi=/signature_uis/05c29951-3e36-438f-9dc6-b681541714ab`}
        ></iframe>
      )}
    </div>
  )
}

export default EcranSignature
