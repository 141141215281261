const validateChamps = (resultats, champ) => {
  if (
    ['facture-achat-velo', 'facture-achat-cadenas'].indexOf(champ.clefApi) > -1
  ) {
    return true
  }
  if (typeof resultats[champ.clefApi] === 'undefined') {
    return false
  }
  if (typeof resultats[champ.clefApi] === 'string') {
    return resultats[champ.clefApi].trim() !== ''
  } else {
    return true
  }
}

export default validateChamps
