import vaeTypes from './vaeTypes'

const setType = (resultats, setResultats) => {
  if (resultats['VEHICULE.CAT_VEH']) {
    const isVAE = vaeTypes.indexOf(resultats['VEHICULE.CAT_VEH']) > -1
    setResultats({
      ...resultats,
      type: isVAE ? 'vae' : 'edpm',
      ...(isVAE && { 'SOUSCRIPTEUR.NATIONALITE': 'FRA' }),
    })
  }
}

export default setType
