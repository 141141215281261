import React from 'react'
import {graphql} from 'gatsby'
import { useRecoilState } from 'recoil'

import resultsState from './resultsState'
import typeState from './typeState'

import vaeTypes from '../../utils/vaeTypes'
import openExternalVaeLink from '../../utils/openExternalVaeLink'
import openExternalJolteeLink from "../../utils/openExternalJolteeLink";

const Select = ({ clefApi, options }) => {
  const [resultats] = useRecoilState(resultsState)
  const [type, _setType] = useRecoilState(typeState)

  let filteredOptions = options
  if (resultats.type !== null && type.only && clefApi === 'VEHICULE.CAT_VEH') {
    if (resultats.type === 'vae') {
      filteredOptions = options.filter(
        (option) =>
          vaeTypes.indexOf(option.clefApi) > -1 || option.clefApi === '5'
      )
    }
    if (resultats.type === 'edpm') {
      filteredOptions = options.filter(
        (option) => vaeTypes.indexOf(option.clefApi) === -1
      )
    }
  }
  return (
    <div
      className={`grid gap-4 mb-6 md:col-span-4 grid-cols-2 ${
        filteredOptions.length > 3
          ? 'md:grid-cols-4'
          : `md:grid-cols-${options.length}`
      }`}
    >
      {filteredOptions.map((option) => {
        const selected = resultats[clefApi] === option.clefApi
        return (
          <div
            className={`cursor-pointer rounded border bg-white p-6 color-gray-500 flex items-center flex-col justify-center ${
              selected ? 'shadow' : 'border-gray-300'
            }`}
            key={option.id}
            onClick={() => {
              if (option.clefApi === 'velo_electrique') {
                openExternalVaeLink();
              } else {
                openExternalJolteeLink(option.clefApi)
              }
            }}
          >
            <div
              className={`rounded-full flex items-center justify-center mb-2 ${
                selected
                  ? 'bg-green-500 text-green-500 bg-opacity-20'
                  : 'bg-gray-500 bg-opacity-10'
              }`}
              style={{
                width: '60px',
                height: '60px',
              }}
              dangerouslySetInnerHTML={{
                __html: option.picto,
              }}
            />
            <h3 className="font-bold text-center">{option.label}</h3>
          </div>
        )
      })}
    </div>
  )
}

export const selectFragment = graphql`
  fragment DatoCmsChampsDeSouscriptionSelectFragment on DatoCmsChampsDeSouscriptionSelect {
    id
    clefApi
    options {
      picto
      clefApi
      id
      label
    }
    model {
      apiKey
    }
  }
`

export default Select
