import { atom } from 'recoil'

// const edpmDefaults = [
//   { 'POLICE.TTC_POL': '57.48', 'POLICE.FORMULE': 'F1' },
//   { 'POLICE.TTC_POL': '95.64', 'POLICE.FORMULE': 'F2' },
//   { 'POLICE.TTC_POL': '153.5', 'POLICE.FORMULE': 'F3' },
// ]

// const vaeDefaults = [
//   {
//     'POLICE.FORMULE': 'formule-unique',
//     'POLICE.TTC_POL': '110',
//   },
// ]

const pricesState = atom({
  key: 'pricesState',
  default: null,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((resultats) => {
        // console.debug(JSON.stringify(resultats))
      })
    },
  ],
})

export default pricesState
