import React from 'react'
import { useRecoilState } from 'recoil'

import Caret from '../../assets/images/caret.inline.svg'
import Tick from '../../assets/images/tick-alt.inline.svg'

import Button from '../Button'
import Link from '../Link'
import Logo from '../Logo'

import ecranState from './ecranState'
import etapeState from './etapeState'

const SouscriptionTopBar = ({ ecrans }) => {
  const [_, setIndexEtape] = useRecoilState(etapeState)
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)
  const indexTarifs = ecrans.findIndex((ecran) => ecran.typeDEcran === 'tarifs')
  const indexUpload = ecrans.findIndex((ecran) => ecran.typeDEcran === 'upload')
  const afterUploadSteps = Array.from(
    Array(ecrans.length - indexUpload - 1).keys()
  )
  return (
    <>
      <div
        className={`relative flex items-center ${
          indexEcran < indexTarifs ? 'justify-center' : 'justify-between'
        } flex-shrink-0 h-24 p-4 md:p-8`}
      >
        {indexEcran > 0 && indexEcran < indexTarifs && (
          <Button
            className="absolute flex items-center transform -translate-y-1/2 left-8 top-1/2"
            variant="gray"
            onClick={() => {
              setIndexEcran(indexEcran - 1)
              setIndexEtape(0)
            }}
          >
            <Caret className="transform -rotate-90 fill-current" />
            <span className="hidden ml-4 md:block">Retour</span>
          </Button>
        )}
        <Link
          to="/"
          className={indexEcran >= indexUpload ? 'absolute left-8 top-8' : ''}
        >
          <Logo />
        </Link>
        {indexEcran < indexTarifs && (
          <div className="absolute font-bold transform -translate-y-1/2 right-8 top-1/2">
            {indexEcran + 1}/{indexTarifs}
          </div>
        )}
        {indexEcran >= indexUpload && (
          <div className="flex items-center justify-end w-full md:w-auto md:justify-center md:mx-auto">
            {afterUploadSteps.map((el, index) => {
              const stepIndex = index + indexUpload
              return (
                <React.Fragment key={index}>
                  <div
                    className={`flex rounded-full border items-center justify-center flex-shrink-0 w-6 h-6 md:w-8 md:h-8 ${
                      indexEcran > stepIndex &&
                      'bg-green-500 text-white border-green-500'
                    } ${
                      indexEcran === stepIndex &&
                      'text-green-500 border-green-500'
                    } ${
                      indexEcran < stepIndex && 'text-gray-200 border-gray-200'
                    }`}
                  >
                    {indexEcran > stepIndex && (
                      <Tick className="stroke-current" />
                    )}
                    {indexEcran <= stepIndex && <span>{index + 1}</span>}
                  </div>
                  {stepIndex < ecrans.length - 2 && (
                    <span className="block mx-1 bg-gray-300 h-px w-2 md:w-3.5"></span>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        )}
      </div>
      {indexEcran < indexTarifs && (
        <div className="flex-shrink-0 w-full h-2 bg-gray-500 bg-opacity-5">
          <div
            className="h-full transition-all duration-1000 rounded-r-full from-green-300 to-green-500 bg-gradient-to-r"
            style={{ width: `${(100 * (indexEcran + 1)) / ecrans.length}%` }}
          />
        </div>
      )}
    </>
  )
}

export default SouscriptionTopBar
