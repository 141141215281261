import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { graphql } from 'gatsby'
import { useRecoilState, useRecoilCallback } from 'recoil'
import { useDropzone } from 'react-dropzone'

import resultsState from './resultsState'
import UploadIcon from '../../assets/images/upload.inline.svg'
import Loading from '../../assets/images/loading.inline.svg'

const Upload = ({ clefApi, label, taille, onChange }) => {
  const [resultats, setResultats] = useRecoilState(resultsState)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const setSuccessResult = useRecoilCallback(({ snapshot }) => async () => {
    const currentResultats = await snapshot.getPromise(resultsState)
    setResultats({
      ...currentResultats,
      [clefApi]: '1',
    })
  })

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const url = `${process.env.GATSBY_WIZZAS_API_URL}/upload-file`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', label)
      formData.append('clefApi', clefApi)
      formData.append('type', resultats.type)
      formData.append('ID_POL', resultats.ID_POL)
      formData.append(
        'PERSONNES.SOUSCRIPTEUR.MAIL',
        resultats['PERSONNES.SOUSCRIPTEUR.MAIL']
      )

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      setError(false)
      setSuccess(false)
      setLoading(true)

      axios
        .post(url, formData, config)
        .then(() => {
          setSuccess(true)
          setLoading(false)
          setSuccessResult()
        })
        .catch((e) => {
          setError(true)
          setLoading(false)
        })
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // TODO: change this to accept more file types??
    accept: 'application/pdf',
  })

  return (
    <div
      {...getRootProps()}
      className={`mb-1 flex flex-col items-center md:${
        taille === 'moitié'
          ? 'col-span-2'
          : taille === 'petit'
          ? 'col-span-1'
          : 'col-span-4'
      } border border-dashed rounded ${!success && 'cursor-pointer'} ${
        error
          ? 'border-red-500 text-red-500'
          : success
          ? 'border-green-500 text-green-500'
          : isDragActive
          ? 'border-green-300 text-green-300'
          : 'border-gray-300 text-gray-300 text-opacity-30'
      } p-12`}
    >
      <div className="flex items-center justify-center w-16 h-16 mb-3">
        {loading ? <Loading /> : <UploadIcon />}
      </div>
      <input {...getInputProps()} />
      <label className="block mb-2 text-xl font-bold text-center">
        {label}
      </label>
      {!success && !error && (
        //  TODO: change file types for CR
        <p className="text-sm">Veuillez uploader ce document au format PDF</p>
      )}
      {error && <p className="text-sm">Veuillez réessayer...</p>}
      {success && (
        <p className="text-sm">Félicitation, ce document est bien uploadé</p>
      )}
    </div>
  )
}

export const uploadFragment = graphql`
  fragment DatoCmsChampsDeSouscriptionUploadFragment on DatoCmsChampsDeSouscriptionUpload {
    id
    clefApi
    label
    taille
    model {
      apiKey
    }
  }
`

export default Upload
