import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import Download from '../../assets/images/download.inline.svg'
import Loading from '../../assets/images/loading.inline.svg'
import Button from '../Button'
import Anna from './Anna'
import resultsState from './resultsState'

const EcranTelechargement = () => {
  const [resultats, _setResultats] = useRecoilState(resultsState)
  const [greenCardUrl, setGreenCardUrl] = useState(null)

  useEffect(() => {
    if (resultats.type === 'edpm') {
      axios
        .get(
          `${process.env.GATSBY_WIZZAS_API_URL}/get-provisory-green-card?ID_POL=${resultats.ID_POL}&email=${resultats['PERSONNES.SOUSCRIPTEUR.MAIL']}&civilite=${resultats['PERSONNES.SOUSCRIPTEUR.CIVILITE']}&nom=${resultats['PERSONNES.SOUSCRIPTEUR.NOM']}`
        )
        .then((result) => {
          if (result.data.url) {
            setGreenCardUrl(result.data.url)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [resultats])

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-12">
      <Anna />
      <h1 className="my-12 text-3xl font-bold text-center">
        Félicitation {resultats['PERSONNES.SOUSCRIPTEUR.PRENOM']}! <br /> C'est
        fait, vous êtes{' '}
        {resultats['PERSONNES.SOUSCRIPTEUR.CIVILITE'] === 'M'
          ? 'assuré'
          : 'assurée'}{' '}
        🍾
      </h1>
      {resultats.type === 'edpm' ? (
        !greenCardUrl ? (
          <p className="max-w-2xl mb-12 text-xl font-bold text-center">
            Nous préparons votre carte verte temporaire, veuillez patienter.
          </p>
        ) : (
          <p className="max-w-2xl mb-12 text-xl font-bold text-center">
            Téléchargez maintenant votre carte verte provisoire, en attendant de
            recevoir votre carte verte définitive par mail.
          </p>
        )
      ) : (
        <p className="max-w-2xl mb-12 text-xl font-bold text-center">
          Nous recevrez votre contrat par email dans les plus brefs délais.
        </p>
      )}
      {resultats.type === 'edpm' ? (
        <a
          target="_blank"
          className="flex flex-col items-center justify-center px-12 py-6 text-white bg-green-500 rounded hover:bg-opacity-70"
          href={greenCardUrl}
        >
          {!greenCardUrl ? <Loading /> : <Download />}
          <span className="mt-4">Ma carte verte temporaire</span>
        </a>
      ) : (
        <Button to="/" variant="green">
          Retour à l'accueil
        </Button>
      )}
    </div>
  )
}

export default EcranTelechargement
