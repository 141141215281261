import React, { useEffect } from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { useRecoilState } from 'recoil'
import { Transition } from '@headlessui/react'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import usePrevious from '../utils/usePrevious'

import EcranFormulaire from '../components/souscription/EcranFormulaire'
import EcranLiasse from '../components/souscription/EcranLiasse'
import EcranPaiement from '../components/souscription/EcranPaiement'
import EcranSignature from '../components/souscription/EcranSignature'
import ecranState from '../components/souscription/ecranState'
import EcranTarifs from '../components/souscription/EcranTarifs'
import EcranTelechargement from '../components/souscription/EcranTelechargement'
import EcranUploads from '../components/souscription/EcranUploads'
import SouscriptionTopBar from '../components/souscription/SouscriptionTopBar'
import resultsState, {
  defaultResultsState,
} from '../components/souscription/resultsState'
import etapeState from '../components/souscription/etapeState'
import pricesState from '../components/souscription/pricesState'


const stripePromiseEDPM = loadStripe(
  process.env.GATSBY_STRIPE_PUBLIC_KEY_EDPM
)
const stripePromiseVAE = loadStripe(
  process.env.GATSBY_STRIPE_PUBLIC_KEY_VAE
)

const Souscription = ({ data }) => {
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)
  const [_indexEtape, setIndexEtape] = useRecoilState(etapeState)
  const [resultats, setResultats] = useRecoilState(resultsState)
  const [_prix, setPrix] = useRecoilState(pricesState)
  const previousIndexEcran = usePrevious(indexEcran)

  const ecrans = data.allDatoCmsEcranDeSouscription.nodes.filter(
    (ecran) => !ecran.moyenAssure || resultats.type === ecran.moyenAssure.slug
  )

  useEffect(() => {
    return () => {
      setResultats(defaultResultsState)
      setIndexEcran(0)
      setIndexEtape(0)
      setPrix(null)
    }
  }, [])


  return (
    <Elements
      stripe={resultats.type === 'vae' ? stripePromiseVAE : stripePromiseEDPM}
    >
      <div className="flex flex-col w-screen h-screen">
        <SouscriptionTopBar ecrans={ecrans} />
        <HelmetDatoCms seo={data.datoCmsPage.seoMetaTags} />
        <div className="relative flex-auto p-12">
          {ecrans.map((ecran, i) => {
            return (
              <Transition
                className="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-start overflow-scroll"
                show={i === indexEcran}
                key={i}
                enter={
                  previousIndexEcran !== indexEcran
                    ? 'transition-all duration-500'
                    : ''
                }
                enterFrom={`opacity-0 transform ${
                  previousIndexEcran < indexEcran
                    ? 'translate-x-full'
                    : previousIndexEcran > indexEcran
                    ? '-translate-x-full'
                    : 'translate-x-0'
                }`}
                enterTo="opacity-100 transform translate-x-0"
                leave="transition-all duration-500"
                leaveFrom="opacity-100 transform translate-x-0"
                leaveTo={`opacity-0 transform ${
                  previousIndexEcran > indexEcran
                    ? 'translate-x-full'
                    : '-translate-x-full'
                }`}
              >
                {ecran.typeDEcran === 'formulaire' && (
                  <EcranFormulaire ecrans={ecrans} i={i} />
                )}
                {ecran.typeDEcran === 'upload' && (
                  <EcranUploads ecrans={ecrans} i={i} />
                )}
                {ecran.typeDEcran === 'tarifs' && (
                  <EcranTarifs ecrans={ecrans} i={i} />
                )}
                {ecran.typeDEcran === 'liasse' && (
                  <EcranLiasse ecrans={ecrans} i={i} />
                )}
                {ecran.typeDEcran === 'paiement' && (
                  <EcranPaiement ecrans={ecrans} i={i} />
                )}
                {ecran.typeDEcran === 'telechargement' && (
                  <EcranTelechargement ecrans={ecrans} i={i} />
                )}
                {ecran.typeDEcran === 'signature' && (
                  <EcranSignature ecrans={ecrans} i={i} />
                )}
              </Transition>
            )
          })}
        </div>
      </div>
    </Elements>
  )
}

export default Souscription

export const query = graphql`
  query SouscriptionPageQuery($id: String) {
    datoCmsPage(id: { eq: $id }) {
      id
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      typeDePage
    }
    allDatoCmsEcranDeSouscription(sort: { order: ASC, fields: position }) {
      nodes {
        typeDEcran
        etapes {
          id
          texte
          aide
          validations {
            condition {
              clefApi
              valeur
              condition
            }
            commentaire
            id
          }
          conditionsDAffichage {
            valeur
            condition
            clefApi
          }
          champs {
            ... on DatoCmsChampsDeSouscriptionSelect {
              ...DatoCmsChampsDeSouscriptionSelectFragment
            }
            ... on DatoCmsChampsDeSouscriptionText {
              ...DatoCmsChampsDeSouscriptionTextFragment
            }
            ... on DatoCmsChampsDeSouscriptionUpload {
              ...DatoCmsChampsDeSouscriptionUploadFragment
            }
            ... on DatoCmsChampsDeSouscriptionIbanBic {
              ...DatoCmsChampsDeSouscriptionIbanBicFragment
            }
          }
          moyenAssure {
            slug
          }
        }
        moyenAssure {
          slug
        }
      }
    }
  }
`
