import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import axios from 'axios'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import pricesState from './pricesState'
import resultsState from './resultsState'

import Button from '../Button'
import Loading from '../../assets/images/loading.inline.svg'
import getPaiementDetails from '../../utils/getPaiementDetails'
import ecranState from './ecranState'

const EcranLiasse = () => {
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)
  const [prix, _setPrix] = useRecoilState(pricesState)
  const [resultats, _setResultats] = useRecoilState(resultsState)
  const [loading, setLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState(null)
  const [accompte, setAccompte] = useState(null)
  const [error, setError] = useState(false)


  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    axios
      .post(`${process.env.GATSBY_WIZZAS_API_URL}/create_charge`, {
        ID_POL: resultats.ID_POL,
        type: resultats.type,
        'PERSONNES.SOUSCRIPTEUR.MAIL': resultats['PERSONNES.SOUSCRIPTEUR.MAIL'],
      })
      .then((result) => {
        setClientSecret(result.data.clientSecret)
        setAccompte(result.data.price)
      })
  }, [])

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }

    setLoading(true)
    setError(false)

    const cardElement = elements.getElement(CardElement)

    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${resultats['PERSONNES.SOUSCRIPTEUR.PRENOM']} ${resultats['PERSONNES.SOUSCRIPTEUR.NOM']}`,
            address: {
              city: resultats['PERSONNES.SOUSCRIPTEUR.ADRESSES.VILLE'],
              country: 'FR',
              line1: resultats['PERSONNES.SOUSCRIPTEUR.ADRESSES.ADRESSE1'],
              line2:
                resultats['PERSONNES.SOUSCRIPTEUR.ADRESSES.COMPLEMENTADRESSE'],
              postal_code: resultats['PERSONNES.SOUSCRIPTEUR.ADRESSES.CP'],
            },
          },
        },
      })


      if (result.error || !result.paymentIntent) {
        throw Error(error?.message || 'Something is not right...')
      }

      await axios.post(
        `${process.env.GATSBY_WIZZAS_API_URL}/confirm_card_payment`,
        {
          ID_POL: resultats.ID_POL,
          paymentIntentId: result.paymentIntent.id,
          type: resultats.type,
          'PERSONNES.SOUSCRIPTEUR.MAIL':
            resultats['PERSONNES.SOUSCRIPTEUR.MAIL'],
        }
      )

      setLoading(false)
      setIndexEcran(indexEcran + 1)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setError(true)
    }
  }

  // OLD version before PROMO codes changes
  // const ttc = prix?.find(
  //   (formule) => formule['POLICE.FORMULE'] === resultats.formule
  // )?.['INFOPOL.TOTALSANS']

  const prixWithoutRemise = prix?.find(
    (formule) => formule['POLICE.FORMULE'] === resultats.formule
  )?.['INFOPOL.TOTALSANS']

  // // ⚠️ INFOPOL.GRATUITE undefined for VAE
  // const gratuite = prix?.find(
  //   (f) => f['POLICE.FORMULE'] === resultats.formule)?.['INFOPOL.GRATUITE']

  // const formuleUnique = resultats.formule === 'formule-unique'

  // // check if VAE or EDPM and assign ttc accordingly
  // let ttc;
  // if (!!prix && formuleUnique) {
  //   ttc = prixWithoutRemise
  // } else {
  //   ttc = prixWithoutRemise - gratuite
  // }

  // La balise TTC_POL prend en compte la gratuité si elle existe.
  const ttc = prix?.find(
    (formule) => formule['POLICE.FORMULE'] === resultats.formule
  )?.['POLICE.TTC_POL'];

  // pour ce calcul il faut prendre sans la gratuite => prendre la balise sans gratuité.
  const { mensualite } = getPaiementDetails(
    resultats.formule,
    prixWithoutRemise
  )

  return (
    <div className="container p-4 mx-auto md:p-12">
      <h1 className="mb-6 text-3xl font-bold text-center">
        Récapitulatif et informations de paiement
      </h1>
      {resultats.cdFrac === 'A' ? (
        <p className="mb-12 text-lg text-center">
          Vous payez en une fois{' '}
          <span className="text-green-500 text-medium">{ttc} €</span> pour
          un an.
        </p>
      ) : (
        <p className="mb-12 text-lg text-center">
          Votre acompte s'élève à{' '}
          <span className="text-green-500 text-medium">{accompte}€</span> pour
          les deux premiers mois puis vous serez débité de{' '}
          <span className="text-green-500 text-medium">
            {mensualite}€ par mois
          </span>
        </p>
      )}
      <div className="justify-center md:flex">
        <div className="relative z-10 items-center w-50 p-6 overflow-hidden bg-white rounded md:p-12 shadow-card">
          {!clientSecret ? (
            <Loading />
          ) : (
            <>
              <h2 className="mb-6 text-xl font-bold">
                Informations de paiement
              </h2>
              <p className="mb-6 text-sm">
                Veuillez entrer votre numéro de carte bancaire, sa date
                d'expiration ainsi que le CVC et votre code postal.
              </p>
              <label className="block mb-1 text-sm font-bold">
                Numéro de carte
              </label>
              <CardElement className="w-full p-3 mb-6 leading-tight text-gray-700 border border-gray-300 border-opacity-50 rounded appearance-none" />
              {resultats.cdFrac === 'M' && (
                <div className="p-4 mb-6 text-sm bg-gray-300 rounded bg-opacity-10">
                  {resultats.type === 'vae'
                    ? 'L’acompte correspond aux 3 premiers mois de prélèvement + 20€ de frais de gestion.'
                    : "L'acompte correspond aux deux premiers mois de prélèvement."}
                  <br />
                  Le prélèvement sera ensuite de {mensualite}€/mois.
                  <br />
                  Votre carte bancaire n'est utilisée que pour le paiement de
                  l'acompte.
                </div>
              )}
              {error && (
                <p className="mb-6 text-red-500">
                  Il y a eu une erreur avec votre paiement. Veuillez réessayer
                </p>
              )}
              <Button
                className="flex items-center justify-center w-full"
                variant="green"
                type="submit"
                disabled={!stripe}
                onClick={() => {
                  !!stripe && handleSubmit()
                }}
              >
                <span>
                  Je paie la somme de{' '}
                  {resultats.cdFrac === 'M' ? accompte : ttc}€
                </span>
                {loading && (
                  <span className="ml-3 -mr-3 text-white">
                    <Loading />
                  </span>
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EcranLiasse
