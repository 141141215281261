import axios from 'axios'

class Poll {
  constructor(url, data, shouldContinueFunction, callback, interval) {
    this.url = url
    this.data = data
    this.callback = callback
    this.interval = interval
    this.shouldContinueFunction = shouldContinueFunction
    this.start = this.start.bind(this)
    this.start()
  }
  start() {
    this.startTime = Number(new Date())
    axios
      .post(this.url, this.data)
      .then((res) => {
        const now = Number(new Date())
        const timeSpent = now - this.startTime
        if (this.shouldContinueFunction(res)) {
          setTimeout(this.start, Math.max(0, this.interval - timeSpent))
        } else {
          this.callback(res)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export default Poll
