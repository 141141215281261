import React from 'react'
import { useRecoilState } from 'recoil'
import setType from '../../utils/setType'

import vaeTypes from '../../utils/vaeTypes'
import validateEtape from '../../utils/validateEtape'

import Button from '../Button'

import ecranState from './ecranState'
import etapeState from './etapeState'
import resultsState from './resultsState'

const NextButton = ({ etapesAAfficher }) => {
  const [resultats, setResultats] = useRecoilState(resultsState)
  const [indexEtape, setIndexEtape] = useRecoilState(etapeState)
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)

  const buttonDisabled =
    indexEtape < etapesAAfficher.length - 1 ||
    !etapesAAfficher.reduce(
      (acc, etape) =>
        acc && validateEtape(etape, resultats, setResultats).validated,
      true
    )

  return (
    <Button
      className="ml-20"
      variant="green"
      disabled={buttonDisabled}
      onClick={async () => {
        if (!buttonDisabled) {
          setIndexEcran(indexEcran + 1)
          setIndexEtape(0)
        }
        setType(resultats, setResultats)
      }}
    >
      Étape suivante
    </Button>
  )
}

export default NextButton
