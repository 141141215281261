import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import EcranFormulaire from './EcranFormulaire'
import EcranLoading from './EcranLoading'
import resultsState from './resultsState'

const EcranUploads = (props) => {
  const [loading, setLoading] = useState(true)
  const [resultats, setResultats] = useRecoilState(resultsState)
  const {
    'personnes.souscripteur.adresses.adresse1': omittedAddress,
    ...cleanResultats
  } = resultats
  useEffect(() => {
    axios
      .post(
        `${process.env.GATSBY_WIZZAS_API_URL}/create-contract`,
        cleanResultats
      )
      .then((createContractRequest) => {
        if (createContractRequest.data?.id) {
          setResultats({
            ID_POL: `${createContractRequest.data.id}`,
            ...resultats,
          })
          setLoading(false)
        }
      })
  }, [])
  return loading ? (
    <EcranLoading
      title={`Merci beaucoup ${resultats['PERSONNES.SOUSCRIPTEUR.PRENOM']}, nous préparons votre contrat...`}
    />
  ) : (
    <EcranFormulaire {...props} />
  )
}

export default EcranUploads
