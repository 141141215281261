import axios from 'axios'
import React, { useEffect, useState, forwardRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useRecoilState } from 'recoil'

import bicycle from '../../assets/images/bicycle.svg'

import etapeState from './etapeState'
import resultsState from './resultsState'

import Formules from '../Formules'
import pricesState from './pricesState'
import EcranLoading from './EcranLoading'
import ecranState from './ecranState'
import Formule from '../Formule'
import Button from '../Button'

const EcranTarifs = () => {
  const [resultats, setResultats] = useRecoilState(resultsState)
  const [_indexEtape, setIndexEtape] = useRecoilState(etapeState)
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)
  const [prix, setPrix] = useRecoilState(pricesState)
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)

  useEffect(async () => {
    if (resultats.type === 'edpm') {
      const prices = await axios.post(
        `${process.env.GATSBY_WIZZAS_API_URL}/get-prices`,
        resultats
      )
      setLoading(false)
      setPrix(
        prices.data.data.responses.map((formule) => formule.persistancesCompl)
      )
    } else {
      setResultats({
        ...resultats,
        formule: 'formule-unique',
      })

      if (resultats['VEHICULE.VALD_VEH'] < 1820) {
        setPrix([
          {
            'POLICE.FORMULE': 'formule-unique',
            'POLICE.TTC_POL':
                150,
            'INFOPOL.TOTALSANS':
              150
          },
        ])
      } else {
        setPrix([
          {
            'POLICE.FORMULE': 'formule-unique',
            'POLICE.TTC_POL':
              Math.ceil(
                100 *
                  0.0825 *
                  parseFloat(resultats['VEHICULE.VALD_VEH'].replace(',', '.'))
              ) / 100,
            'INFOPOL.TOTALSANS':
              Math.ceil(
                100 *
                  0.0825 *
                  parseFloat(resultats['VEHICULE.VALD_VEH'].replace(',', '.'))
              ) / 100,
          },
        ])
      }
      setLoading(false)
    }
  }, [])

  return loading ? (
    <EcranLoading
      title={`${resultats['PERSONNES.SOUSCRIPTEUR.PRENOM']}, nous calculons vos tarifs`}
    />
  ) : (
    <div className="container mx-auto">
      <h1 className="mb-12 text-3xl font-bold text-center">
        {resultats['PERSONNES.SOUSCRIPTEUR.PRENOM']}, voici votre devis pour
        rouler en toute sécurité et l'esprit léger! 🎉
      </h1>
      {resultats.type === 'edpm' && !!prix && prix.length > 0 && (
        <Formules
          className="mb-12"
          moyenAssureSlug={resultats.type}
          prix={prix}
          actif={resultats.formule}
          onClick={(formule) => {
            setResultats({
              ...resultats,
              formule,
            })
            setIndexEcran(indexEcran + 1)
            setIndexEtape(0)
          }}
        />
      )}
      {
        <div className="flex items-center mb-12">
          <div className="flex mx-auto bg-gray-500 rounded-full bg-opacity-10">
            {['M', 'A'].map((choix) => (
              <div
                className={`px-6 py-3 rounded-full ${
                  choix === resultats.cdFrac
                    ? 'text-white bg-gray-500'
                    : 'cursor-pointer'
                }`}
                key={choix}
                onClick={() =>
                  choix !== resultats.cdFrac &&
                  setResultats({
                    ...resultats,
                    cdFrac: choix,
                  })
                }
              >
                {choix === 'M' ? 'Par mois' : 'Par an'}
              </div>
            ))}
          </div>
        </div>
      }
      {resultats.type === 'vae' && (
        <div className="flex justify-center mb-12">
          <StaticQuery
            query={graphql`
              query FormuleVAE {
                allDatoCmsFormule(filter: { slug: { eq: "formule-unique" } }) {
                  nodes {
                    clefApi
                    slug
                    apresPrix
                    id
                    items {
                      id
                      inclus
                      item {
                        titre
                      }
                    }
                    picto
                    prix
                    titre
                    slug
                  }
                }
              }
            `}
            render={(data) => {
              return (
                <Formule
                  className="p-12"
                  actif={true}
                  formule={data.allDatoCmsFormule.nodes[0]}
                  moyenAssure={{
                    couleur: 'purple',
                  }}
                  disabledButton={true}
                  prix={prix}
                />
              )
            }}
          />
          <div className="items-center w-1/3 ml-12 overflow-hidden bg-white rounded shadow-card">
            <div className="p-12">
              <h2 className="mb-6 text-xl font-bold">Souscription</h2>
              <p className="mb-6 text-sm">
                Veuillez prendre connaissance des Conditions Générales et
                assurez-vous d'avoir deux cadenas pour vous assurer.
              </p>
              <label className="flex items-start p-4 mb-6 bg-gray-300 cursor-pointer bg-opacity-20">
                <input
                  type="checkbox"
                  className="flex-shrink-0 mt-1 text-green-500 form-checkbox"
                  value="checked"
                  checked={checked}
                  onChange={(e) => {
                    setChecked(!checked)
                  }}
                />
                <span className="ml-2">
                  J'ai bien pris connaissance des{' '}
                  <a
                    className="text-green-500"
                    href="https://www.datocms-assets.com/49386/1643128557-ipid-conditions-generales-albingia.pdf"
                    target="_blank"
                  >
                    Conditions Générales et Particulières
                  </a>
                </span>
              </label>
              <Button
                className="flex items-center justify-center w-full"
                variant="green"
                type="submit"
                disabled={!checked}
                onClick={() => {
                  setIndexEcran(indexEcran + 1)
                  setIndexEtape(0)
                }}
              >
                <span>Je souscris maintenant</span>
              </Button>
            </div>
            <img src={bicycle} alt="VAE" className="w-full h-auto" />
          </div>
        </div>
      )}
    </div>
  )
}

export default EcranTarifs
