import React, { useCallback } from 'react'
import { graphql } from 'gatsby'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete'
import DatePicker, { registerLocale } from 'react-datepicker'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useRecoilState } from 'recoil'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import fr from 'date-fns/locale/fr'
registerLocale('fr', fr)

import DepartementsSelect from './DepartementsSelect'
import NationalitesSelect from './NationalitesSelect'
import resultsState from './resultsState'

const NumberInputButton = ({ className, ...props }) => (
  <button
    className={`w-8 h-8 leading-8 text-center bg-gray-500 bg-opacity-5 rounded-full ${className} hover:bg-opacity-10 cursor-pointer`}
    {...props}
  />
)

const Text = ({
  clefApi,
  typeDeChamps,
  label,
  placeholder,
  placeholderVae,
  taille,
  onChange,
}) => {
  const [resultats, setResultats] = useRecoilState(resultsState)
  const realPlaceholder =
    resultats.type === 'vae' && !!placeholderVae ? placeholderVae : placeholder

  const increment = useCallback(
    (value) => () => {
      setResultats({
        ...resultats,
        [clefApi]: `${
          parseInt(
            typeof resultats[clefApi] !== 'undefined' ? resultats[clefApi] : '0'
          ) + value
        }`,
      })
    },
    [resultats[clefApi]]
  )

  // console.log(resultats)

  function handleChange(e) {
    // value must match 0 or more digits with 0,1 or 2 decimals
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
    if (validated) {
      setResultats({
        ...resultats,
        [clefApi]: e.target.value,
      })
    }
    onChange(e.target.value)
  }





  return (
    <div
      className={`mb-6 md:${taille === 'moitié' ? 'col-span-2' : 'col-span-4'}`}
    >
      <label className="block mb-2 text-sm">{label}</label>
      <div className={`flex items-center ${!!taille && 'w-full'}`}>
        {typeDeChamps === 'civilite' ? (
          <div className="flex items-center">
            <label className="flex items-center mr-4 cursor-pointer">
              <input
                type="radio"
                className="text-green-500 form-radio"
                value="M."
                checked={resultats[clefApi] === 'M.'}
                onChange={(e) => {
                  setResultats({
                    ...resultats,
                    [clefApi]: e.target.value,
                  })
                  onChange(e.target.value)
                }}
              />
              <span className="ml-2">M.</span>
            </label>
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                className="text-green-500 form-radio"
                value="MME"
                checked={resultats[clefApi] === 'MME'}
                onChange={(e) => {
                  setResultats({
                    ...resultats,
                    [clefApi]: e.target.value,
                  })
                  onChange(e.target.value)
                }}
              />
              <span className="ml-2">MME</span>
            </label>
          </div>
        ) : typeDeChamps === 'adresse' ? (
          <GooglePlacesAutocomplete
            selectProps={{
              value: resultats[clefApi] || '',
              noOptionsMessage: () => 'Indiquez votre adresse',
              onChange: async (value) => {
                const geocode = await geocodeByAddress(value.label)
                const address_components = geocode[0].address_components
                setResultats({
                  ...resultats,
                  'PERSONNES.SOUSCRIPTEUR.ADRESSES.ADRESSE1':
                    address_components.find(
                      (c) => c.types.indexOf('route') > -1
                    )?.long_name,
                  'PERSONNES.SOUSCRIPTEUR.ADRESSES.CP': address_components.find(
                    (c) => c.types.indexOf('postal_code') > -1
                  )?.long_name,
                  'PERSONNES.SOUSCRIPTEUR.ADRESSES.VOIE':
                    address_components.find(
                      (c) => c.types.indexOf('street_number') > -1
                    )?.long_name,
                  'PERSONNES.SOUSCRIPTEUR.ADRESSES.VILLE':
                    address_components.find(
                      (c) => c.types.indexOf('locality') > -1
                    )?.long_name,
                  [clefApi]: value,
                })
                onChange(value)
              },
              styles: {
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
              },
            }}
            apiKey={process.env.GATSBY_MAPS_API}
            apiOptions={{ language: 'fr', region: 'fr' }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['fr'],
              },
            }}
          />
        ) : typeDeChamps === 'telephone' ? (
          <PhoneInput
            defaultCountry="FR"
            placeholder={realPlaceholder}
            className={`px-4 py-3 leading-tight text-gray-700 placeholder-gray-500 border border-gray-500 rounded appearance-none placeholder-opacity-30 border-opacity-20 focus:outline-none focus:border-opacity-30 ${
              !!taille && 'w-full'
            }`}
            value={resultats[clefApi] || ''}
            onChange={(value) => {
              setResultats({
                ...resultats,
                [clefApi]: value,
              })
              onChange(value)
            }}
          />
        ) : typeDeChamps === 'nationalite' ? (
          <NationalitesSelect
            taille={taille}
            placeholder={realPlaceholder}
            value={resultats[clefApi] || ''}
            onChange={(e) => {
              setResultats({
                ...resultats,
                [clefApi]: e.target.value,
              })
              onChange(e.target.value)
            }}
          />
        ) : typeDeChamps === 'departements' ? (
          <DepartementsSelect
            taille={taille}
            placeholder={realPlaceholder}
            value={resultats[clefApi] || ''}
            onChange={(e) => {
              setResultats({
                ...resultats,
                [clefApi]: e.target.value,
              })
              onChange(e.target.value)
            }}
          />
        ) : typeDeChamps === 'date' ? (
          <span className="relative z-20 inline-flex items-center text-green-500">
            <DatePicker
              locale="fr"
              selected={
                resultats[clefApi]
                  ? parse(resultats[clefApi], 'dd/MM/yyyy', new Date())
                  : ''
              }
              onChange={(value) => {
                const date = format(value, 'dd/MM/yyyy')
                setResultats({
                  ...resultats,
                  [clefApi]: date,
                })
                onChange(date)
              }}
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              dropdownMode="select"
              dateFormat="dd/MM/yyyy"
              nextMonthButtonLabel=">"
              previousMonthButtonLabel="<"
              customInput={
                <input
                  type="text"
                  className={`px-4 py-3 leading-tight text-gray-700 placeholder-gray-500 border border-gray-500 rounded appearance-none placeholder-opacity-30 border-opacity-20 focus:outline-none focus:border-opacity-30 ${
                    !!taille && 'w-full'
                  }`}
                />
              }
            />
          </span>
        ) : typeDeChamps === 'nombre' ? (
          <>
            <NumberInputButton className="mr-4" onClick={increment(-1)}>
              -
            </NumberInputButton>
            <input
              type="number"
              step="0.01"
              placeholder={realPlaceholder}
              className={`px-4 py-3 leading-tight text-gray-700 placeholder-gray-500 border border-gray-500 rounded appearance-none placeholder-opacity-30 border-opacity-20 focus:outline-none focus:border-opacity-30 ${
                !!taille && 'w-full'
              }`}
              value={resultats[clefApi] || ''}
              onChange={handleChange}
            />

            <NumberInputButton className="ml-4" onClick={increment(1)}>
              +
            </NumberInputButton>
          </>
        ) : (
          <input
            type="text"
            placeholder={realPlaceholder}
            className={`px-4 py-3 leading-tight text-gray-700 placeholder-gray-500 border border-gray-500 rounded appearance-none placeholder-opacity-30 border-opacity-20 focus:outline-none focus:border-opacity-30 ${
              !!taille && 'w-full'
            }`}
            value={resultats[clefApi] || ''}
            onChange={(e) => {
              setResultats({
                ...resultats,
                [clefApi]: e.target.value,
              })
              onChange(e.target.value)
            }}
          />
        )}
      </div>
    </div>
  )
}

export const textFragment = graphql`
  fragment DatoCmsChampsDeSouscriptionTextFragment on DatoCmsChampsDeSouscriptionText {
    id
    clefApi
    label
    typeDeChamps
    taille
    placeholder
    placeholderVae
    model {
      apiKey
    }
  }
`

export default Text
