import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Transition } from '@headlessui/react'
import parse from 'date-fns/parse'
import differenceInYears from 'date-fns/differenceInYears'

import isConditionFullfilled from '../../utils/isConditionFullfilled'
import validateEtape, { getChamps } from '../../utils/validateEtape'
import Question from '../../assets/images/question.inline.svg'

import Anna from './Anna'
import Select from './Select'
import Text from './Text'
import Upload from './Upload'

import resultsState from './resultsState'
import etapeState from './etapeState'
import ecranState from './ecranState'
import NextButton from './NextButton'

const Ecran = ({ ecrans, i }) => {
  const [indexEtape, setIndexEtape] = useRecoilState(etapeState)
  const [indexEcran, _] = useRecoilState(ecranState)
  const [resultats, setResultats] = useRecoilState(resultsState)
  const [showAide, setShowAide] = useState(false)

  const ecran = ecrans[i]
  const etapes = ecran.etapes

  const etapesAAfficher = etapes.filter((etape) => {
    if (etape.champs.find((c) => c.clefApi === 'VEHICULE.IMMAT_VEH')) {
      if (resultats['VEHICULE.CAT_VEH'] !== '5') {
        return false
      }
    }
    if (etape.champs.find((c) => c.clefApi === 'VEHICULE.NO_SERIE_VEH')) {
      if (
        resultats['VEHICULE.CAT_VEH'] === '5' &&
        resultats['VEHICULE.VITESSE'] === '45'
      ) {
        return false
      }
    }

    // condition to display 2n conductor fields
    if (etape.champs.find( (c) => c.clefApi === 'SOUSCRIPTEUR_SECONDAIRE.WNOMCOND1')) {
      const dateNaissance = parse(resultats['INFOPOL.DATENAISS'], 'dd/MM/yyyy', new Date())

      const age = differenceInYears(new Date(), dateNaissance)

      // Velo eclectrique or Speedbike
      if (
        resultats['VEHICULE.CAT_VEH'] === 'velo_electrique' ||
        (resultats['VEHICULE.CAT_VEH'] === '5' && age < 18)
      ) {
        return false
      }

      // Gyropode
      if (resultats['VEHICULE.CAT_VEH'] === '3' && age < 16) {
        return false
      }

      // EDPM
      if (age >= 18 || age < 14) {
        return false
      }
    }

    return (
      (!etape.moyenAssure || resultats.type === etape.moyenAssure.slug) &&
      (etape.conditionsDAffichage.length === 0 ||
        etape.conditionsDAffichage.reduce(
          (acc, condition) =>
            acc &&
            isConditionFullfilled(
              resultats[condition.clefApi],
              condition.valeur,
              condition.condition
            ),
          true
        ))
    )
  })


  return (
    <>
      {etapesAAfficher.map((etape, j) => {
        const { commentaires } = validateEtape(etape, resultats, setResultats)
        const etapeShown = j <= indexEtape
        const champs = getChamps(etape, resultats)

        return (
          <Transition
            className="container items-start block py-8 pl-8 pr-8 mx-auto md:flex lg:pr-80"
            show={etapeShown}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            key={etape.id}
          >
            <Transition
              show={etapeShown}
              className="flex-shrink-0"
              enter="transition-all duration-1000"
              enterFrom="opacity-0 transform translate-y-1/2"
              enterTo="opacity-100 transform translate-y-0"
            >
              <Anna />
            </Transition>
            <div className="flex-auto">
              <div className="mb-2 font-bold">Anna</div>
              <div className="relative flex items-center mb-6">
                <div
                  className="flex-auto"
                  dangerouslySetInnerHTML={{
                    __html: etape.texte
                      .replace(/p2/g, 'p2 mb-2')
                      .replace(/{.*?}/g, function (match) {
                        return resultats[
                          match.replace('{', '').replace('}', '')
                        ]
                      }),
                  }}
                />
                {!!etape.aide && (
                  <Question
                    className="flex-shrink-0 w-6 h-6 ml-3 cursor-pointer md:w-8 md:h-8"
                    onMouseEnter={() => setShowAide(etape.id)}
                    onMouseLeave={() => setShowAide(false)}
                  />
                )}
                {!!etape.aide && etape.id === showAide && (
                  <div className="absolute p-6 ml-6 transform -translate-y-1/2 shadow-xl left-full top-1/2 w-72">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: etape.aide
                          .replace(/p2/g, 'p2 mb-2')
                          .replace(/{.*?}/g, function (match) {
                            return resultats[
                              match.replace('{', '').replace('}', '')
                            ]
                          }),
                      }}
                    />
                    <div
                      className="absolute transform -translate-y-1/2 right-full top-1/2"
                      style={{
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderRight: '10px solid #fff',
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 gap-3 md:grid-cols-4">
                {champs.map((champsItem, index) => {
                  const Champs =
                    champsItem.model.apiKey === 'champs_de_souscription_select'
                      ? Select
                      : champsItem.model.apiKey ===
                        'champs_de_souscription_text'
                      ? Text
                      : Upload

                  return (
                    <Champs
                      {...champsItem}
                      key={index}
                      onChange={(value) => {
                        if (
                          j === indexEtape &&
                          etapesAAfficher.length >= j &&
                          validateEtape(etape, {
                            ...resultats,
                            [champsItem.clefApi]: value,
                          }).validated
                        ) {
                          setIndexEtape(indexEtape + 1)
                        }
                      }}
                    />
                  )
                })}
              </div>

              {commentaires.length > 0 &&
                commentaires.map(
                  ({ commentaire, id, CommentaireComponent }) => (
                    <div key={id} className="flex my-6">
                      <Anna small />
                      {!!commentaire && (
                        <div
                          className="max-w-lg p-4 bg-gray-100 rounded-r rounded-tl"
                          dangerouslySetInnerHTML={{
                            __html: commentaire,
                          }}
                        />
                      )}
                      {!!CommentaireComponent && (
                        <div className="max-w-lg p-4 bg-gray-100 rounded-r rounded-tl">
                          <CommentaireComponent />
                        </div>
                      )}
                    </div>
                  )
                )}
            </div>
          </Transition>
        )
      })}
      <div className="container pl-8 mx-auto mb-12">
        <NextButton etapesAAfficher={etapesAAfficher} />
      </div>
    </>
  )
}

export default Ecran
