import React from 'react'
import differenceInYears from 'date-fns/differenceInYears'
import getYear from 'date-fns/getYear'
import parse from 'date-fns/parse'

import isConditionFullfilled from './isConditionFullfilled'
import validateChamps from './validateChamps'

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const getChamps = (etape, resultats) => {
  return etape.champs.filter((champ) => {

    if (champ.model.apiKey === 'champs_de_souscription_upload') {
      if (
        champ.clefApi === '11000013' &&
        resultats['VEHICULE.CAT_VEH'] !== '3'
        // GYROPODE ET ATTESTATION DE FORMATION
      ) {
        return false
      }
      if (
        champ.clefApi === '11000061' &&
        resultats['formule'] !== 'F3'
        // FORMULE C ET FACTURE D'ACHAT
      ) {
        return false
      }
      if (
        champ.clefApi === '11000036' &&
        resultats['VEHICULE.CAT_VEH'] !== '5'
        // SPEEDBIKE ET PERMIS DE CONDUIRE
      ) {
        return false
      }
      if (
        champ.clefApi === '11000100' &&
        resultats['VEHICULE.CAT_VEH'] === '5'
        // SPEEDBIKE ET CNI
      ) {
        return false
      }
      if (
        champ.clefApi === '11000246' &&
        resultats['VEHICULE.CAT_VEH'] !== '5'
        // SPEEDBIKE ET CARTE GRISE
      ) {
        return false
      }
    }
    return true
  })
}

const validateEtape = (etape, resultats, setResultats) => {
  const champs = getChamps(etape, resultats)

  let buttonDisabled = !champs.reduce(
    (acc, champ) => acc && validateChamps(resultats, champ),
    true
  )

  let commentaires = []
  if (etape.validations) {
    // VALIDATIONS AUTOMATIQUES
    etape.validations.forEach(({ condition, commentaire, id }) => {
      let valueToTest = resultats[condition.clefApi]

      // CHAMPS YEAR
      if (champs.find((champ) => champ.clefApi)?.typeDeChamps === 'date') {
        valueToTest = getYear(valueToTest)
      }

      if (
        typeof valueToTest !== 'undefined' &&
        isConditionFullfilled(
          valueToTest,
          condition.valeur,
          condition.condition
        )
      ) {
        buttonDisabled = true
        commentaires = [...commentaires, { commentaire, id }]
      }
    })

    // VALIDATIONS MANUELLES
    champs.forEach((champ, index) => {
      if (typeof resultats[champ.clefApi] !== 'undefined') {
        // DATE DE NAISSANCE
        if (champ.clefApi === 'INFOPOL.DATENAISS') {
          let ageMinimum = 14
          let ageMaximum = 80

          if (resultats['VEHICULE.CAT_VEH'] === '3') {
            // GYROPODE
            ageMinimum = 16
          }

          if (
            resultats['VEHICULE.CAT_VEH'] === '5' ||
            resultats['VEHICULE.VITESSE'] === '45'
          ) {
            // SPEEDBIKE
            ageMinimum = 18
          }

          if (resultats['VEHICULE.CAT_VEH'] === 'velo_electrique') {
            ageMinimum = 18
          }

          const date = parse(resultats[champ.clefApi], 'dd/MM/yyyy', new Date())
          const age = differenceInYears(new Date(), date)

          if (age > ageMaximum || age < ageMinimum) {
            buttonDisabled = true
            commentaires = [
              ...commentaires,
              {
                commentaire: `Vous devez avoir entre ${ageMinimum} et ${ageMaximum} ans pour pouvoir vous assurer.`,
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
          // Message to warn young user to give souscripteur input.
          if (age >= ageMinimum && age < 18) {
            // age > 14 and < 18
            commentaires = [
              ...commentaires,
              {
                commentaire: `Vous avez ${age} ans, vous êtes mineur vous ne pouvez pas vous assurer directement. Merci de saisir les données d'un souscripteur majeur.
                `,
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
        }

        // PRIX D'ACHAT
        if (champ.clefApi === 'VEHICULE.VALD_VEH') {
          const valeur = parseFloat(resultats['VEHICULE.VALD_VEH'])
          let prixMinimum = 0

          if (valeur < prixMinimum || valeur > 10000) {
            buttonDisabled = true
            commentaires = [
              ...commentaires,
              {
                commentaire: `Le prix de votre véhicule doit être compris entre ${prixMinimum}€ et 10000€`,
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
        }

        // Date D'ACHAT
        if (champ.clefApi === 'INFOPOL.DATEACHAT') {
          const date = parse(resultats[champ.clefApi],'dd/MM/yyyy',new Date())
          const age = differenceInYears(new Date(),date)
          if (
            (age >= 10 && resultats.type === 'edpm') ||
            (resultats.type === 'vae' && age >= 3)
          ) {
            buttonDisabled = true
            commentaires = [
              ...commentaires,
              {
                commentaire: `Votre véhicule est trop ancien, nous ne pouvons pas l'assurer`,
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
        }
        // Age min 18ans EDPM souscripteur/conducteur secondaire
        if (champ.clefApi === 'SOUSCRIPTEUR_SECONDAIRE.WDNCOND1') {
          const date = parse(resultats[champ.clefApi],'dd/MM/yyyy',new Date())
          const age = differenceInYears(new Date(), date)
          if (age < 18 && resultats.type === 'edpm') {
            buttonDisabled = true
            commentaires = [
              ...commentaires,
              {
                commentaire: `Le souscripteur doit avoir 18 ans pour souscrire le contrat.`,
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
        }

        // VITESSE TROP GRANDE ET VAE
        // PRIX D'ACHAT
        if (champ.clefApi === 'VEHICULE.VITESSE') {
          if (resultats.type === 'vae' && resultats[champ.clefApi] === '45') {
            buttonDisabled = true
            commentaires = [
              ...commentaires,
              {
                CommentaireComponent: () => (
                  <div>
                    Nous sommes désolés, mais nous ne sommes pas en mesure de
                    vous proposer une offre d'assurance pour votre véhicule car
                    aucune offre ne nous permet d'assurer un vélo dont la
                    vitesse dépasse 25 km/h. Un speed bike est assimilé à un
                    cyclomoteur. <br />
                    Vous êtes soumis aux mêmes réglementations qu'un scooter
                    50cc :
                    <ul className="list-disc list-inside">
                      <li>Obligation d’assurance</li>
                      <li>
                        Obligation d’immatriculation (une carte grise est
                        délivrée par la Préfecture)
                      </li>
                      <li>Obligation du port du casque (normé "scooter")</li>
                    </ul>
                    <br />
                    <a
                      className="font-bold text-green-500 underline cursor-pointer"
                      onClick={() => {
                        setResultats({
                          ...resultats,
                          'VEHICULE.CAT_VEH': '5',
                          type: 'edpm',
                        })
                      }}
                    >
                      Découvrez l'assurance Speed Bike
                    </a>
                  </div>
                ),
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
        }

        if (champ.clefApi === 'PERSONNES.SOUSCRIPTEUR.MAIL') {
          if (!validateEmail(resultats[champ.clefApi])) {
            buttonDisabled = true
            commentaires = [
              ...commentaires,
              {
                commentaire: `Vous devez entrer un email valide`,
                id: `${champ.clefApi}_${index}`,
              },
            ]
          }
        }
      }
    })
  }

  return { validated: !buttonDisabled, commentaires }
}

export default validateEtape
