const isConditionFullfilled = (a, b, operator) => {
  switch (operator) {
    case '<':
      return parseFloat(a) < parseFloat(b)
    case '>':
      return parseFloat(a) > parseFloat(b)
    case '>=':
      return parseFloat(a) >= parseFloat(b)
    case '<=':
      return parseFloat(a) <= parseFloat(b)
    case '=':
      return a == b
    case '!=':
      return a != b
    default:
      return false
  }
}

export default isConditionFullfilled
