import React from 'react'
import anna from '../../assets/images/anna.png'

const Anna = ({ small, ...props }) => (
  <div
    className={`relative mx-auto md:ml-0 md:mr-4 ${
      small ? 'w-10 h-10' : 'w-10 h-10 md:w-16 md:h-16'
    }`}
    {...props}
  >
    <img
      src={anna}
      width="140px"
      height="140px"
      alt="Anna"
      className={small ? 'w-10 h-10' : 'w-10 h-10 md:w-16 md:h-16'}
    />
    {!small && (
      <div className="absolute bottom-0 right-0 hidden w-4 h-4 bg-green-500 border-2 border-white rounded-full md:block"></div>
    )}
  </div>
)

export default Anna
