import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { Transition } from '@headlessui/react'
import axios from 'axios'

import etapeState from './etapeState'
import ecranState from './ecranState'

import Button from '../Button'
import Anna from './Anna'
import resultsState from './resultsState'

const EcranLiasse = ({ ecrans, i }) => {
  const [_indexEtape, setIndexEtape] = useRecoilState(etapeState)
  const [indexEcran, setIndexEcran] = useRecoilState(ecranState)
  const [resultats, _setResultats] = useRecoilState(resultsState)
  const [checks, setChecks] = useState({
    dispositionsGenerales: false,
    declarations: false,
  })

  useEffect(async () => {
    if (resultats.type === 'edpm') {
      await axios.post(`${process.env.GATSBY_WIZZAS_API_URL}/get-prices`, {
        ...resultats,
        sendLiasse: true,
      })
    }
  }, [])

  const buttonDisabled = !checks.declarations || !checks.dispositionsGenerales

  return (
    <div className="container py-8 pl-8 pr-8 mx-auto">
      <Transition
        className="flex items-start lg:pr-80"
        show={true}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Transition
          show={true}
          className="flex-shrink-0"
          enter="transition-all duration-1000"
          enterFrom="opacity-0 transform translate-y-1/2"
          enterTo="opacity-100 transform translate-y-0"
        >
          <Anna />
        </Transition>
        <div className="flex-auto">
          <div className="mb-2 font-bold">Anna</div>
          <div className="relative flex items-center mb-6">
            <div className="flex-auto">
              Nous vous avons envoyé une liasse pré-contractuelle par email.
              Merci de bien vouloir la consulter avant de souscrire 🙏{' '}
            </div>
          </div>
          <label className="flex items-start p-4 mb-6 mr-4 bg-gray-300 cursor-pointer bg-opacity-20">
            <input
              type="checkbox"
              className="flex-shrink-0 mt-1 text-green-500 form-checkbox"
              value="dispositionsGenerales"
              checked={checks.dispositionsGenerales}
              onChange={(e) => {
                setChecks({
                  ...checks,
                  dispositionsGenerales: !checks.dispositionsGenerales,
                })
              }}
            />
            <span className="ml-2">
              J'ai bien pris connaissance des{' '}
              <a
                className="text-green-500"
                href="https://www.datocms-assets.com/49386/1632819300-dg-wizzaseqwiz0786202104.pdf"
                target="_blank"
              >
                Dispositions Générales
              </a>
            </span>
          </label>
          <label className="flex items-start p-4 mb-6 mr-4 bg-gray-300 cursor-pointer bg-opacity-20">
            <input
              type="checkbox"
              className="flex-shrink-0 mt-1 text-green-500 form-checkbox"
              value="declarations"
              checked={checks.declarations}
              onChange={(e) => {
                setChecks({
                  ...checks,
                  declarations: !checks.declarations,
                })
              }}
            />
            <div className="ml-2">
              Je déclare et je reconnais :
              <ul className="list-disc list-inside">
                <li>
                  que chaque véhicule est strictement de série courante avec le
                  moteur standard du constructeur, et qu'il n’a subi aucune
                  transformation ou modification notamment en ce qui concerne sa
                  puissance ou sa vitesse (débridage) ;
                </li>
                <li>
                  être apte à la maîtrise, au pilotage et à la conduite du
                  véhicule assuré ;
                </li>
                <li>
                  que toutes les informations que j'ai fournies sont sincères et
                  exactes.
                </li>
                <li>
                  que toute réticence et toute déclaration intentionnellement
                  fausse, toute omission ou déclaration inexacte, sont soumises,
                  selon le cas, aux sanctions prévues aux articles L 113-8 et L
                  113-9 du Code des assurances.
                </li>
              </ul>
            </div>
          </label>
        </div>
      </Transition>
      <Button
        className="ml-20"
        variant="green"
        disabled={buttonDisabled}
        onClick={async () => {
          if (!buttonDisabled) {
            setIndexEcran(indexEcran + 1)
            setIndexEtape(0)
          }
        }}
      >
        Étape suivante
      </Button>
    </div>
  )
}

export default EcranLiasse
